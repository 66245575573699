<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-3"></div>

      <div class="col-md-6 register-container">
        <div class="card register">
          <div class="card-body">
            <p class="card-title pb-4">{{ $t('views.forms.registration') }}</p>
            <p class="card-text white-text pb-4" v-html="$t('views.forms.registration_body')"></p>
            <div class="mb-5">
              <input
                type="text"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.first_name')+' *'"
                v-model="user.first_name"
                name="Vorname"
                v-validate="'required'"
              />
              <div v-if="submitted && !user.first_name" class="red-text m-2 error-mssg">Der Vorname ist ein Pflichfeld</div>

              <input
                type="text"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.last_name')+' *'"
                v-model="user.last_name"
                name="Nachname"
                v-validate="'required'"
              />
              <div v-if="submitted && !user.last_name" class="red-text m-2 error-mssg">Der Nachname ist ein Pflichfeld</div>

              <input
                type="text"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.company')"
                v-model="user.company"
                name="Company"
              />
              <input
                type="text"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.position')"
                v-model="user.company_position"
                name="Position"
              />
              <input
                v-validate="'required|customPlz'"
                type="text"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.plz')+' *'"
                v-model="user.plz"
                name="PLZ"
              />
              <div
                v-if="submitted && !user.plz"
                class="red-text m-2 error-mssg"
              >Die Postleitzahl ist ein Pflichfeld und darf nur aus Zahlen bestehen</div>
            </div>

            <input
              type="text"
              class="form-control mb-4 text-field"
              :placeholder="$t('views.forms.place_holders.email')+' *'"
              v-model="user.email"
              name="email"
              v-validate="'required|customEmail'"
            />
            <div v-if="submitted && !user.email" class="red-text m-2 error-mssg">Die E-Mail Adresse ist ein Pflichtfeld</div>
            <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first('email') }}</div>

            <div class="password-input">
              <input
                type="password"
                id="password"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.password')"
                v-model="user.password"
                name="Password"
                v-validate="'required'"
              />
              <span
                class="show-eye"
                toggle="#password-field"
                :class="eyeClass"
                @click="togglePassword()"
              ></span>
              <input
                type="password"
                id="input-confirm-pwd"
                class="form-control mb-4 text-field"
                :placeholder="$t('views.forms.place_holders.password_check')"
                v-model="user.password_check"
                name="Password"
                v-validate="'required'"
              />
              <span
                class="show-eye"
                toggle="#input-confirm-pwd"
                :class="eyeClassConfirm"
                @click="toggleConfirmPassword()"
              ></span>
            </div>
            <div
              v-if="submitted && (user.password !== user.password_check)"
              class="red-text m-2 error-mssg"
            >Passwörter stimmen nicht überein</div>
            <div v-if="submitted && !user.password_check" class="red-text m-2 error-mssg">Das Passwortfeld darf nicht leer sein</div>
            <!-- <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="appointmentregisterFormRemember"
                v-model="user.accept_contact"
              />
              <label
                class="form-check-label white-text checkbox-text"
                for="appointmentregisterFormRemember"
              >
                <span class="privacy__accept">Ich möchte gerne einen Termin für eine persönliche Führung mit einem Häfele Fachberater vereinbaren (Bitte PLZ angeben)</span>
              </label>
            </div> -->
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="materialregisterFormRemember"
                v-validate="'required'"
                v-model="user.accept_terms"
              />
              <label
                class="form-check-label white-text checkbox-text--privacy"
                for="materialregisterFormRemember"
              >
                <span class="privacy__accept">Hiermit bestätige ich die <span @click="toData()" class="text__bold link__hover">Datenschutzbestimmungen.</span></span>
                <!-- <br />
                <span class="privacy__accept--full">{{ $t('views.forms.accept_privacy_full') }}</span> -->
              </label>
            </div>
            <div
              v-if="submitted && !user.accept_terms"
              class="red-text m-2 error-mssg"
            >{{ $t('views.forms.terms') }}</div>
            <div>
              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible"
                :sitekey="siteKey"
              ></vue-recaptcha>
              <button
                :disabled="status==='submitting'"
                class="btn btn-block mt-4 p-2"
                @click="validateForm()"
              >{{ $t('views.forms.register') }}</button>
            </div>
            <div class="footer">
              
              <div class="card-text footer pt-4 pd-4">
                <p class="register-text">
                  {{ $t('views.forms.already_user') }}
                  <a class="white-text link__hover" @click="goToLogin()">
                    <span>{{ $t('views.forms.back') }}</span>
                  </a>
                </p>
                 <p class="register-text">
                  Haben Sie Schwierigkeiten bei der Registrierung? <br/>Klicken Sie
                  <a class="white-text link__hover" href="mailto:vertriebs-event@haefele.de?subject=Supportanfrage%20Schwierigkeiten%20mit%20der%20Registrierung">
                    <span>HIER</span>
                  </a>
                </p>
              </div>
              {{ $t('views.forms.recaptcha_protection') }}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
              >{{ $t('views.forms.privacy_policy') }}</a>
              {{ $t('views.forms.and') }}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
              >{{ $t('views.forms.terms_of_services') }}</a>.
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        password: '',
        password_check: '',
        plz:'',
        // Added those hard coded as there are not yet implemented in our front end
        company_position: '',
        inviter_id: '',
        accept_terms: false,
        accept_contact: false,
        recaptcha_token: 'asdf'
      },
      status: '',
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      firmaName: '',
      submitted: false,
      type: 'password',
      eyeClass: 'fa fa-fw fa-eye field-icon toggle-password',
      eyeClassConfirm: 'fa fa-fw fa-eye field-icon toggle-password'
    };
  },
  methods: {
    togglePassword() {
      if (this.eyeClass.includes('fa-eye-slash')) {
        this.eyeClass = this.eyeClass.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('password').type = 'password';
      } else {
        this.eyeClass = this.eyeClass.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('password').type = 'text';
      }
    },
    toggleConfirmPassword() {
      if (this.eyeClassConfirm.includes('fa-eye-slash')) {
        this.eyeClassConfirm = this.eyeClassConfirm.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-confirm-pwd').type = 'password';
      } else {
        this.eyeClassConfirm = this.eyeClassConfirm.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-confirm-pwd').type = 'text';
      }
    },
    validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          console.log('valid');
          this.$refs.recaptcha.execute();
        }
      });
    },
    toData(){
      let routeData = this.$router.resolve({name: 'datenschutzerklaerung'});
      window.open(routeData.href, '_blank');  
    },

    showNoteMessage() {
      this.$store.state.modal = true;
      this.$store.state.modalMessage = 'Die Email wird bereits verwendet';
      this.$store.state.modalIcon = 'fas fa-times-circle';
      this.$store.state.modalClass = 'red-text';
      this.submitted = false;
      setTimeout(
        ()=> {
          this.$store.state.modal = false;
        },
        3000
      );
    },
    async register() {
      if (this.user.password !== this.user.password_check) {
        this.validateForm();
        return;
      }
      let resp = await this.$store.dispatch('authRegister', this.user);
      if(resp ==='failed'){
        this.showNoteMessage(); 
      }
    },
    goToLogin() {
      this.$router.push('/login');
    },
    onCaptchaVerified(recaptchaToken) {
      this.status = 'submitting';
      this.$refs.recaptcha.reset();
      this.user.recaptcha_token = recaptchaToken;
      this.register();
      this.status = '';
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    }
  },
  created() {
    this.firmaName = this.$route.query.inviter || 'Hafele';
    this.user.inviter_id = this.$route.query.inviter_id || '4';
  },
  mounted() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    );
    recaptchaScript.setAttribute('async', '');
    recaptchaScript.setAttribute('defer', '');
    document.head.appendChild(recaptchaScript);
  }
};


</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -1;
}

input {
  margin-bottom: 20px;
}

.footer {
  margin-top: 10px !important;
  margin-bottom: 0p !important;
  font-size: 10px;
  color: white;
  font-weight: 300;
}

.error-mssg {
  font-size: 12px;
  text-shadow: 0px 0px 10px #000;
}

.toggle-password:hover {
  cursor: pointer;
}

.card-body {
  width: 100%;
  margin: auto;
  padding: 2rem 8%;
  height: 80vh;
  overflow-y: scroll;
}

.text__bold{
  font-weight: 700;

  &:hover{
    cursor: pointer;
  }
}

.container {
  margin-top: 20px;
}

.register {
  background-color: rgba(80, 80, 80, 0.9);
  margin-bottom: 40px !important;
}

.link__hover{
  &:hover{
    color: $secondaryColor !important;
  }
}
.card {
  width: 100%;
  border-radius: 0;
  box-shadow: 1px 1px 5px rgba(80, 80, 80, 1);
}

.card-title {
  font-weight: 300;
  color: $secondaryColor;
  font-size: 42px;
  padding-bottom: 10px !important;
}

.card-text {
  font-size: 16px !important;
  font-weight: 400;
}

.form-check {
  padding-bottom: 10px;
}

input {
  font-size: 16px !important;
}

.register .text-field {
  border-radius: 0%;
  background-color: rgba(80, 80, 80, 0.9);
  color: white;
  border-color: rgba(255, 255, 255, 0.7);
}

.register ::placeholder {
  color: #ffffff99;
  opacity: 0.5;
}

.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -53px;
  position: relative;
  z-index: 2;
  color: white;
  opacity: 0.8;
}

.card .card-body .card-text {
  letter-spacing: 0.49px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.49px !important;
  opacity: 1;
  font-weight: 400;
  padding-bottom: 6% !important;
}

.checkbox-text {
  font-size: 16px;

  &--privacy {
    line-height: 1.1em;
  }
}

.checkbox-text span {
  font-weight: 700;
}

.privacy__accept {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 1.6em;

  &--full {
    font-size: 10px !important;
    font-weight: 300;
  }
}

.register .btn {
  color: black;
  background-color: white;
  text-align: left;
  height: 37px;
  letter-spacing: 0.15px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 7% !important;
  text-transform: none;
  border-radius: 0 !important;
}

.register .btn:hover {
  background-color: $secondaryColor;
}

.register-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.49px;
}

.register-text span {
  font-size: 16px;
  font-weight: 700;
}

.mb-5 {
  margin-bottom: 4rem !important;
}

@media screen and (max-device-width: 640px) and (orientation: landscape) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .card-text {
    font-size: 10px !important;
  }

  input {
    font-size: 10px !important;
  }

  .register .btn {
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 10px !important;
  }

  .checkbox-text {
    font-size: 10px !important;
    line-height: 1.1em;
  }

  p {
    font-size: 14px !important;
  }
}

.privacy__accept {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 1.6em;

  &--full {
    font-size: 10px !important;
    font-weight: 300;
  }
}
@media screen and (max-device-width: 1024px) {
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
